import { User } from "./user.model";
import { WorkShop } from "./workshop.model";

export class UserWorkshop {
    wu_w_id: number;
    wu_u_id: number;
    wu_id: number;
    wu_check_in: Date;
    wu_check_out: Date;
    wu_creation_date: Date;
    wu_u_: User;
    wu_w_: WorkShop
    constructor(data) {
        Object.assign(this, data)
    }
}